import _ from 'lodash';
import RouteData from './route-data'
import { route_registry } from './subapps/registry';
import { app, grabToken } from './utils/feathers';

const RouteMapper = {
  Home: { import: () => import('./pages/landing-page') },
}

const { routes = {}, data = {} } = _.get(route_registry, process.env.SITE_ID)

Object.assign(RouteMapper, routes)
Object.assign(RouteData, data)

const Routes = _.mapValues(RouteData, (routeName) => {
  const cmp = RouteMapper[routeName]
  if (cmp && cmp.import) {
    return {
      onmatch: async () => {
        if (!app.get('user')) await grabToken()

        if (cmp.authenticate && !app.get('user')) {
          return m.route.set('/')
        }

        return cmp.import().then(m => m[cmp.property || 'default'])
      }
    }
  } else {
    return { render: vnode => m(cmp) };
  }
});

const DefaultRoute = '/'
m.route.prefix = ""

export { Routes, DefaultRoute };