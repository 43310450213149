const RouteData = {
  '/': 'Home',
  '/slideshow': 'Slideshow',
  '/missions': 'Missions',
  '/ministry-partners': 'Ministry_partners',
  '/church-ministries': 'Church_ministries',
  '/upcoming-events': 'Upcoming_events',
  '/church-groups': 'Church_groups',
  '/campus-map': 'Campus_map',
  '/admin/': 'Admin',
  '/admin/missions-partner': 'Admin_missions_partner',
  '/admin/forms/missions-partner': 'Admin_missions_partner_form',
  '/admin/forms/missions-partner/updates': 'Admin_missions_partner_updates',
  '/admin/ministry-partner': 'Admin_ministry_partner',
  '/admin/forms/ministry-partner': 'Admin_ministry_partner_form',
  '/admin/church-ministries': 'Admin_church_ministries',
  '/admin/forms/church-ministries': 'Admin_church_ministries_form',
  '/admin/church-groups': 'Admin_church_groups',
  '/admin/forms/church-groups': 'Admin_church_groups_form',

  '/tools/churchteams-donors': 'Churchteams_donors',
  '/tools/img-resizer': 'ImgResizer',
  '/tools/slideshower': 'SlideShower'
};

module.exports = RouteData