import SelfSufficient from './self-sufficient'

m.n = (tags, ...rest) => {
  const parts = tags.split('>')
  return parts.reduceRight((child, tag) => m(tag.trim(), child), m(parts.pop().trim(), ...rest))
}

m.link = (selector, ...args) => {
  let attrs = args[0], children = args

  // has attrs
  if (_.isPlainObject(attrs) && !_.isVnode(attrs)) {
    children = args.slice(1)
  } else {
    attrs = {}
  }

  return m(m.route.Link, Object.assign({ selector }, attrs), children)
}

m.route.setparam = (params, config={}) => {
  const { overwrite = true, key = true } = config
  const qp = overwrite ? params : Object.assign(m.route.param(), params)
  const cur_url = m.route.get().split(/[?#]/)[0]
  m.route.set(cur_url, qp, key ? { state: { key: _.uniqueId() } } : {})
}

m.route.sethash = (hash, config = {}) => {
  const { replace = true } = config
  if (replace) {
    if (!hash.startsWith('#')) hash = '#' + hash
    history.replaceState(null, '', location.pathname + location.search + hash);
  } else {
    location.hash = hash
  }
}

const isolate = (sel, compFn) => {
  return (vnode) => {
    let s, redraw = () => s && s.redraw()
    vnode.redraw = redraw
    const component = compFn(vnode)
    return {
      ...component,
      view: vnode => {
        return m(SelfSufficient, {
          root: m(sel),
          view: state => {
            s = state
            return component.view(vnode)
          }
        })
      }
    }
  }
}
_.set(m, 'isolate', isolate)