import ytscribe_routes from './ytscribe/routes'
import ytscribe_routedata from './ytscribe/route-data'

import pbcc_routes from './pbcc/routes'
import pbcc_routedata from './pbcc/route-data'

import candlestick_routes from './candlestick/routes'
import candlestick_routedata from './candlestick/route-data'

export const route_registry = {
  ytscribe: {
    routes: ytscribe_routes,
    data: ytscribe_routedata,
  },
  pbcc: {
    routes: pbcc_routes,
    data: pbcc_routedata,
  },
  candlestick: {
    routes: candlestick_routes,
    data: candlestick_routedata,
  },
}