// Function to black out the screen and show a message
function blackoutScreen(message = '') {
  // Create a blackout overlay div
  const blackoutDiv = document.createElement('div');
  blackoutDiv.id = 'blackoutOverlay';
  blackoutDiv.style.position = 'fixed';
  blackoutDiv.style.top = '0';
  blackoutDiv.style.left = '0';
  blackoutDiv.style.width = '100%';
  blackoutDiv.style.height = '100%';
  blackoutDiv.style.backgroundColor = 'black';
  blackoutDiv.style.color = 'white';
  blackoutDiv.style.fontSize = '24px';
  blackoutDiv.style.textAlign = 'center';
  blackoutDiv.style.display = 'flex';
  blackoutDiv.style.justifyContent = 'center';
  blackoutDiv.style.alignItems = 'center';
  blackoutDiv.innerHTML = `<div>${message}</div>`;

  // Append the blackout div to the body
  document.body.appendChild(blackoutDiv);
}

// Function to restore the kiosk to its normal state
function restoreScreen() {
  // Remove the blackout overlay div
  const blackoutDiv = document.getElementById('blackoutOverlay');
  if (blackoutDiv) {
    blackoutDiv.remove();
  }
}

// Function to set the blackout state and save it in localStorage
function setBlackoutState(isBlackout, message) {
  localStorage.setItem('kioskState', isBlackout ? 'blackout' : 'normal');
  if (isBlackout) {
      blackoutScreen(message);
  } else {
      restoreScreen();
  }
}

// Function to initialize the state on page load
function initializeState(blackoutSchedules) {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinutes = now.getMinutes();
  const totalMinutes = currentHour * 60 + currentMinutes;

  let isBlackout = false;
  let blackoutMessage = '';

  blackoutSchedules.forEach(schedule => {
      const blackoutStart = schedule.startHour * 60 + schedule.startMinute;
      const blackoutEnd = schedule.endHour * 60 + schedule.endMinute;

      if (totalMinutes >= blackoutStart && totalMinutes < blackoutEnd) {
          isBlackout = true;
          blackoutMessage = schedule.message;
      }
  });

  setBlackoutState(isBlackout, blackoutMessage);
}

// Function to continuously check the time and update the screen state
function startBlackoutMonitor(blackoutSchedules) {
  setInterval(() => {
      initializeState(blackoutSchedules);
  }, 60000); // Check every minute
}

// Example usage:
const blackoutSchedules = [
  { startHour: 8, startMinute: 25, endHour: 9, endMinute: 30, message: "Church service in progress" },
  { startHour: 10, startMinute: 25, endHour: 11, endMinute: 30, message: "Church service in progress" },
  // { startHour: 17, startMinute: 0, endHour: 6, endMinute: 30, message: "Idle" },
  // Add more blackout times and messages as needed
];

// Initialize the state on page load
window.addEventListener('load', () => initializeState(blackoutSchedules));

// Start monitoring the blackout times
startBlackoutMonitor(blackoutSchedules);

console.log("LOADING CRON")