_.mixin({
  timeout: ms => new Promise(done => setTimeout(done, ms)),
  nest2dot: o => {
    const out = {}
    const inner = (o, prefix = '') => {
      if (_.isPlainObject(o) || _.isArray(o)) {
        for (let k in o) {
          inner(o[k], prefix ? prefix + '.' + k : k)
        }
      } else {
        out[prefix] = o
      }
    }
    inner(o)
    return out
  },
  dot2nest: o => {
    const out = {}
    for (let k in o) {
      _.set(out, k, o[k])
    }
    return out
  },
  strEquals: (s1, s2) => String(s1) === String(s2),
  raf: () => new Promise(done => requestAnimationFrame(done)),
  isVnode: x => typeof x !== "object" || x.tag != null || Array.isArray(x),
  devlog: (...args) => {
    if (process.env.NODE_ENV !== 'production') console.log(...args)
  },
  pp: json => console.log(JSON.stringify(json, null, 2))
});


const memoizeWithExpiration = (func, expirationTime = 60000) => {
  const memoizedFunc = _.memoize(func);
  const cache = memoizedFunc.cache;

  const clearCacheAfterExpiration = (key) => {
    setTimeout(() => {
      cache.delete(key);
    }, expirationTime);
  };

  return (...args) => {
    const cacheKey = JSON.stringify(args);

    if (!cache.has(cacheKey)) {
      const result = memoizedFunc(...args);
      cache.set(cacheKey, result);
      clearCacheAfterExpiration(cacheKey);
    }

    return cache.get(cacheKey);
  };
}

_.mixin({ memoizeWithExpiration })