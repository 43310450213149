import './cron'

const RouteMapper = {
  Home: { import: () => import('./pages/index') },
  Missions: { import: () => import('./pages/missions') },
  Ministry_partners: { import: () => import('./pages/ministry-partner') },
  Upcoming_events: { import: () => import('./pages/upcoming-events') },
  Church_groups: { import: () => import('./pages/church-groups') },
  Campus_map: { import: () => import('./pages/campus-map') },
  Church_ministries: { import: () => import('./pages/church-ministries') },

  Slideshow: { import: () => import('./pages/slideshow') },

  Admin: { import: () => import('./pages/admin/') },
  Admin_missions_partner: { authenticate: true, import: () => import('./pages/admin/missions-partner') },
  Admin_missions_partner_form: { authenticate: true, import: () => import('./pages/admin/forms/missions-partner') },
  Admin_missions_partner_updates: { authenticate: true, import: () => import('./pages/admin/forms/missions-partner'), property: 'UpdatesForm' },
  Admin_ministry_partner: { authenticate: true, import: () => import('./pages/admin/ministry-partner') },
  Admin_ministry_partner_form: { authenticate: true, import: () => import('./pages/admin/forms/ministry-partner') },
  Admin_church_ministries: { authenticate: true, import: () => import('./pages/admin/church-ministry') },
  Admin_church_ministries_form: { authenticate: true, import: () => import('./pages/admin/forms/church-ministry') },
  Admin_church_groups: { authenticate: true, import: () => import('./pages/admin/church-groups') },
  Admin_church_groups_form: { authenticate: true, import: () => import('./pages/admin/forms/church-groups') },

  Churchteams_donors: { import: () => import('./pages/tools/churchteams-donors') },
  ImgResizer: { import: () => import('./pages/tools/img-resizer') },
  SlideShower: { import: () => import('./pages/tools/slideshower') },
}

export default RouteMapper